<script setup>
import { ref, nextTick, useAttrs, watch } from 'vue';
import { Link, router } from '@inertiajs/vue3';
import { PrimaryButton, ShinyButton, SecondaryButton, TertiaryButton, SimulationCard, Card, Spinner, SimulationSearchResult, PersoneoLogo, SearchInput } from '@/Components';
import { Pattern } from '../../images/backgrounds';
import { breakpointsTailwind, useBreakpoints, useUrlSearchParams, watchDebounced } from '@vueuse/core';
import { register as registerSwiper } from 'swiper/element/bundle';
import { vOnClickOutside } from '@vueuse/components'
import { useFlashMessages } from '@/composables';
import { ChatBubbleLeftIcon, SparklesIcon, UserIcon, UserGroupIcon } from '@heroicons/vue/24/solid';

registerSwiper();

const props = defineProps({
    preselectedSimulations: { type: Array, required: true },
    featuredSimulations: { type: Array, required: true },
    latestSimulations: { type: Array, required: true },
    popularSimulations: { type: Array, required: true },
    audienceSimulations: { type: Array, required: true },
    tags: { type: Array, required: true }
});

const { roleplaysDoneCount, roleplaysCreatedCount, creatorsCount, partnersCount } = useAttrs();

useFlashMessages();

const searchParams = useUrlSearchParams();

const { tag: selectedTag = props.tags[0] } = searchParams;

const featuredSwiperProgress = ref(0);

const onFeaturedSwiperProgress = (e) => {
    const [swiper, progress] = e.detail;
    featuredSwiperProgress.value = progress;
};

const latestSwiperProgress = ref(0);

const onLatestSwiperProgress = (e) => {
    const [swiper, progress] = e.detail;
    latestSwiperProgress.value = progress;
};

const popularSwiperProgress = ref(0);

const onPopularSwiperProgress = (e) => {
    const [swiper, progress] = e.detail;
    popularSwiperProgress.value = progress;
};

const taggedSwiperProgress = ref(0);

const onTaggedSwiperProgress = (e) => {
    const [swiper, progress] = e.detail;
    taggedSwiperProgress.value = progress;
};

const breakpoints = useBreakpoints(breakpointsTailwind);

const getSlidesPerView = (totalSlidesCount) => {
    if (breakpoints.greaterOrEqual('xl').value) {
        return Math.min(totalSlidesCount, 4);
    }

    if (breakpoints.greaterOrEqual('lg').value) {
        return Math.min(totalSlidesCount, 3);
    }

    if (breakpoints.greaterOrEqual('md').value) {
        return Math.min(totalSlidesCount, 2);
    }

    return 1.2;
};

const searchInput = ref('');
const searchResults = ref([]);
const searchStatus = ref('ready');

let abortSearch = new AbortController()

watchDebounced(searchInput, async (searchValue) => {
    abortSearch.abort("New fetch");

    if (searchValue.length < 3) {
        searchResults.value = [];
        searchStatus.value = 'ready';
        return;
    }

    abortSearch = new AbortController()

    // make sure it set to loading after running aborting previous request and running finally 
    nextTick(() => searchStatus.value = 'loading');

    try {
        const response = await fetch(route('search', { q: searchValue, entity: 'roleplays' }), {
            signal: abortSearch.signal,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'X-CSRF-Token': document.querySelector('input[name="_token"]').value,
            },
            credentials: 'same-origin',
        });
    
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const simulations = await response.json();
        searchResults.value = simulations;
    } finally {
        searchStatus.value = 'ready';
    }
}, { debounce: 300 });

function navigateToSearchPage() {
    router.get(route('search', { q: searchInput.value || undefined, entity: 'roleplays' }));
}

function clearSearchInput() {
    searchInput.value = '';
}

const tagRefs = ref([]);

watch(() => searchParams.tag, async function scrollTagIntoView(newTag) {
    const index = props.tags.indexOf(newTag);
    if (index === -1) return;
    await nextTick();
    const tagRef = tagRefs.value[index];
    const $tagEl = tagRef.$el;
    $tagEl.scrollIntoView({ inline: 'center', block: 'nearest', behavior: 'smooth' });
}, { immediate: true });
</script>

<template>
    <div class="bg-gradient min-h-screen">
        <div class="max-w-screen-xl mx-auto py-8 px-4">
            <nav class="flex justify-between items-center gap-6">
                <Link :href="route('landingpage')" class="flex items-center gap-x-3">
                    <PersoneoLogo />
                </Link>
                <div class="search grow max-w-md relative">
                    <SearchInput 
                        placeholder="Search for roleplays" 
                        class="hidden sm:block"
                        v-model="searchInput"
                        v-on-click-outside="clearSearchInput"
                        @keyup.enter="navigateToSearchPage"
                    />

                    <div class="results absolute top-full translate-y-4 left-0 right-0 max-h-96 overflow-y-auto p-2 bg-white rounded-2xl" v-if="searchInput">
                        <div v-if="searchInput.length < 3" class="my-6">
                            <p class="text-center italic text-muted">Can you be more spcific? Type {{ 3 - searchInput.length }} more character(s).</p>
                        </div>
                        <div v-else-if="searchStatus === 'loading'" class="my-6">
                            <div class="flex justify-center">
                                <Spinner class="h-8 text-primary animate-spin" />
                            </div>
                        </div>
                        <div v-else-if="searchResults.length === 0 && searchInput" class="my-6">
                            <p class="text-center italic text-muted">No roleplays found. Maybe make one?</p>
                            <div class="flex justify-center mt-6">
                                <Link :href="route('simulations.create')">
                                    <ShinyButton>Create roleplay</ShinyButton>
                                </Link>
                            </div>
                        </div>
                        <Link 
                            v-else 
                            v-for="simulation in searchResults" 
                            :href="route('simulations.show', { roleplayUuid: simulation.uuid })"
                            :key="simulation.id" 
                        >
                            <SimulationSearchResult :simulation="simulation" />
                        </Link>
                    </div>
                </div>
                <div class="flex items-center gap-x-3">
                    <Link :href="route('register')">
                        <PrimaryButton>Sign Up</PrimaryButton>
                    </Link>
                    <Link :href="route('login')">
                        <SecondaryButton>Login</SecondaryButton>
                    </Link>
                </div>
            </nav>

            <header class="mt-8 md:mt-16 lg:mt-24">
                <div class="grid grid-cols-10 gap-5">
                    <Card 
                        class="col-span-10 lg:col-span-7 lg:row-span-2 justify-between flex flex-col"
                        :bgImage="Pattern"
                    >
                        <div class="flex flex-col">
                            <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold max-w-md">Biggest AI roleplay marketplace</h1>
                            <p class="text-md md:text-xl lg:text-2xl mt-4 md:mt-6">Master your communication skills with hyper realistic role plays</p>
                        </div>
                        <div class="grid grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-4 mt-6">
                            <div class="flex flex-col gap-y-2">
                                <div class="flex gap-x-3 items-center mx-auto">
                                    <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                        <ChatBubbleLeftIcon class="fill-white w-5"/>
                                    </div>
                                    <div class="text-xl font-bold w-12">{{ roleplaysDoneCount }}</div>
                                </div>
                                <div class="text-xs uppercase text-white/90 font-semibold text-center tracking-tighter">Roleplays done</div>
                            </div>
                            <div class="flex flex-col gap-y-2">
                                <div class="flex gap-x-3 items-center mx-auto">
                                    <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                        <SparklesIcon class="fill-white w-5"/>
                                    </div>
                                    <div class="text-xl font-bold w-12">{{ roleplaysCreatedCount }}</div>
                                </div>
                                <div class="text-xs uppercase text-white/90 font-semibold text-center tracking-tighter">Roleplays created</div>
                            </div>
                            <div class="flex flex-col gap-y-2">
                                <div class="flex gap-x-3 items-center mx-auto">
                                    <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                        <UserIcon class="fill-white w-5"/>
                                    </div>
                                    <div class="text-xl font-bold w-12">{{ creatorsCount }}</div>
                                </div>
                                <div class="text-xs uppercase text-white/90 font-semibold text-center tracking-tighter">Creators</div>
                            </div>
                            <div class="flex flex-col gap-y-2">
                                <div class="flex gap-x-3 items-center mx-auto">
                                    <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                        <UserGroupIcon class="fill-white w-5"/>
                                    </div>
                                    <div class="text-xl font-bold w-12">{{ partnersCount }}</div>
                                </div>
                                <div class="text-xs uppercase text-white/90 font-semibold text-center tracking-tighter">Partners</div>
                            </div>
                        </div>
                    </Card>
                    <Link class="col-span-10 sm:col-span-5 lg:col-span-3" v-for="simulation in preselectedSimulations" :href="route('simulations.show', { roleplayUuid: simulation.uuid })">
                        <div class="w-full relative overflow-hidden p-4 bg-white rounded-2xl md:rounded-3xl transition-all duration-300 ease-out [box-shadow:0_35px_18px_-30px_var(--shadow-color,hsl(220,23%,85%))] hover:[box-shadow:0_55px_27px_-50px_var(--shadow-color,hsl(220,23%,87%))] hover:scale-105 hover:cursor-pointer">
                            <div class="h-full">
                                <div class="flex flex-col items-stretch gap-y-3 h-full">
                                    <div class="shrink-0 grow-0 h-[2em] md:h-[3em] flex items-center gap-x-4">
                                        <img :src="simulation.thumbnail ? simulation.thumbnail : LogoColor" class="h-full aspect-square rounded-full object-cover shrink-0 grow-0" />
                                        <div class="h-full flex flex-col grow justify-center">
                                            <p class="font-semibold text-[1em] text-black leading-[1.15] text-ellipsis overflow-hidden [-webkit-box-orient:vertical] [display:-webkit-box] [-webkit-line-clamp:3]">{{ simulation.name }}</p>
                                        </div>
                                    </div>
                                    <div class="grow text-gray-500 [font-size:0.875em] [line-height:1.3em] overflow-hidden text-justify [-webkit-box-orient:vertical] [display:-webkit-box] [-webkit-line-clamp:4]">
                                        {{ simulation.scenario.description.length > 100 ? simulation.scenario.description.substring(0, 100) + ' ...' : simulation.scenario.description }}
                                    </div>
                                    <div>Reply...</div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </header>

            <main class="mt-12 space-y-8">
                <section>
                    <h2 class="font-semibold text-xl text-slate-700 uppercase">Featured</h2>
                    <div class="swiper-container-wrapper">
                        <swiper-container
                            class="mySwiper -mx-3 w-full"
                            :space-between="16"
                            :slides-per-view="getSlidesPerView(featuredSimulations.length)"
                            :navigation="true"
                            :pagination="false"
                            :scrollbar="false"
                            :mousewheel="true"
                            :slidesOffsetBefore="0"
                            :slidesOffsetAfter="0"
                            :mousewheel-force-to-axis="true"
                            :injectStyles="[
                                `.swiper {padding: 0 0.75rem;}`,
                                `.swiper-button-next { margin-right: 0.25rem; }`,
                                `.swiper-button-prev { margin-left: 0.25rem; }`,
                            ]"
                            style="--swiper-navigation-color: #6212E5; --swiper-navigation-size: 1.5rem;"
                            :style="{
                                '--swiper-btn-prev-opacity': featuredSwiperProgress === 0 ? 0 : 1,
                                '--swiper-btn-next-opacity': featuredSwiperProgress === 1 ? 0 : 1,
                            }"
                            @swiperprogress="onFeaturedSwiperProgress"
                        >
                            <div slot="container-start" v-if="featuredSwiperProgress !== 0 && featuredSimulations.length > getSlidesPerView(featuredSimulations.length)">
                                <div class="swiper-fade-left md:opacity-0"></div>
                            </div>
                            <div slot="container-end" v-if="featuredSwiperProgress !== 1 && featuredSimulations.length > getSlidesPerView(featuredSimulations.length)">
                                <div class="swiper-fade-right md:opacity-0"></div>
                            </div>
                            <swiper-slide v-for="(simulation, index) in featuredSimulations" :key="index" class="my-8 h-auto">
                                <Link 
                                    :href="route('simulations.show', { roleplayUuid: simulation.uuid })"
                                >
                                    <SimulationCard
                                        :simulation="simulation"
                                        style="--shadow-color: hsla(255,75%,55%,0.5)"
                                        class="h-full"
                                    />
                                </Link>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </section>

                <section>
                    <h2 class="font-semibold text-xl text-slate-700 uppercase">Latest</h2>
                    <!-- Mobile -->
                    <div class="swiper-container-wrapper mt-2 block md:hidden">
                        <swiper-container
                            class="mySwiper -mx-3 w-full"
                            :space-between="16"
                            :slides-per-view="1.2"
                            :navigation="true"
                            :pagination="false"
                            :scrollbar="false"
                            :mousewheel="true"
                            :slidesOffsetBefore="0"
                            :slidesOffsetAfter="0"
                            :mousewheel-force-to-axis="true"
                            :injectStyles="[
                                `.swiper {padding: 0 0.75rem;}`,
                                `.swiper-button-next { margin-right: 0.25rem; }`,
                                `.swiper-button-prev { margin-left: 0.25rem; }`,
                            ]"
                            style="--swiper-navigation-color: #6212E5; --swiper-navigation-size: 1.5rem;"
                            :style="{
                                '--swiper-btn-prev-opacity': latestSwiperProgress === 0 ? 0 : 1,
                                '--swiper-btn-next-opacity': latestSwiperProgress === 1 ? 0 : 1,
                            }"
                            @swiperprogress="onLatestSwiperProgress"
                        >
                            <div slot="container-start" v-if="latestSwiperProgress !== 0 && latestSimulations.length > getSlidesPerView(latestSimulations.length)">
                                <div class="swiper-fade-left"></div>
                            </div>
                            <div slot="container-end" v-if="latestSwiperProgress !== 1 && latestSimulations.length > getSlidesPerView(latestSimulations.length)">
                                <div class="swiper-fade-right"></div>
                            </div>
                            <swiper-slide v-for="(simulation, index) in latestSimulations" :key="index" class="my-8 h-auto">
                                <Link 
                                    :href="route('simulations.show', { roleplayUuid: simulation.uuid })"
                                >
                                    <SimulationCard
                                        :simulation="{ ...simulation, scenario: { ...simulation.scenario, description: simulation.scenario.description.length > 100 ? simulation.scenario.description.substring(0, 100) + ' ...' : simulation.scenario.description }}"
                                        style="--shadow-color: hsla(255,75%,55%,0.5)"
                                        class="h-full"
                                    />
                                </Link>
                            </swiper-slide>
                        </swiper-container>
                    </div>

                    <!-- Tablets and desktops -->
                    <div class="hidden mt-10 md:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 gap-y-12">
                        <Link 
                            v-for="simulation in latestSimulations"
                            :key="simulation.id"
                            :href="route('simulations.show', { roleplayUuid: simulation.uuid })"
                            class="flex"
                        >
                            <SimulationCard class="grow" :simulation="simulation" style="--shadow-color: hsla(255,75%,55%,0.5)" />
                        </Link>
                    </div>
                </section>

                <section>
                    <div class="flex justify-center">
                        <div class="flex py-6 md:py-12 overflow-x-auto -mx-4">
                            <Link
                                v-for="tag in tags"
                                ref="tagRefs"
                                :href="route('landingpage', { tag })"
                                :preserveScroll="true"
                                :key="tag" 
                                class="px-6 py-3 rounded-3xl font-semibold capitalize mx-1 first:ml-4 last:mr-4 text-nowrap"
                                :class="[tag === selectedTag ? 'bg-primary text-white' : 'bg-white text-gray-800']"
                            >{{ tag }}</Link>
                        </div>
                    </div>

                    <div class="swiper-container-wrapper">
                        <swiper-container
                            class="mySwiper -mx-3 w-full"
                            :space-between="16"
                            :slides-per-view="getSlidesPerView(audienceSimulations.length)"
                            :navigation="true"
                            :pagination="false"
                            :scrollbar="false"
                            :mousewheel="true"
                            :slidesOffsetBefore="0"
                            :slidesOffsetAfter="0"
                            :mousewheel-force-to-axis="true"
                            :injectStyles="[
                                `.swiper {padding: 0 0.75rem;}`,
                                `.swiper-button-next { margin-right: 0.25rem; }`,
                                `.swiper-button-prev { margin-left: 0.25rem; }`,
                            ]"
                            style="--swiper-navigation-color: #6212E5; --swiper-navigation-size: 1.5rem;"
                            :style="{
                                '--swiper-btn-prev-opacity': taggedSwiperProgress === 0 ? 0 : 1,
                                '--swiper-btn-next-opacity': taggedSwiperProgress === 1 ? 0 : 1,
                            }"
                            @swiperprogress="onTaggedSwiperProgress"
                        >
                            <div slot="container-start" v-if="taggedSwiperProgress !== 0 && audienceSimulations.length > getSlidesPerView(audienceSimulations.length)">
                                <div class="swiper-fade-left md:opacity-0"></div>
                            </div>
                            <div slot="container-end" v-if="taggedSwiperProgress !== 1 && audienceSimulations.length > getSlidesPerView(audienceSimulations.length)">
                                <div class="swiper-fade-right md:opacity-0"></div>
                            </div>
                            <swiper-slide v-for="(simulation, index) in audienceSimulations" :key="index" class="my-8 h-auto text-center flex justify-center">
                                <Link :href="route('simulations.show', { roleplayUuid: simulation.uuid })">
                                    <SimulationCard 
                                        :simulation="simulation"
                                        class="max-w-xs text-left h-full"
                                        style="--shadow-color: hsla(255,75%,55%,0.5)"
                                    />
                                </Link>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </section>

                <section class="flex flex-wrap justify-center gap-5">
                    <Card class="w-96 flex flex-col justify-between" :bgImage="Pattern">
                        <h2 class="text-lg font-bold">Looking for L&D program for your company?</h2>
                        <TertiaryButton :as="Link" :href="route('contact.show')" class="inline-block w-fit mt-6 hover:scale-105 transition-all duration-300 ease-out">Try a business plan</TertiaryButton>
                    </Card>
                    <Card class="w-96 flex flex-col justify-between" :bgImage="Pattern">
                        <h2 class="text-lg font-bold">Want to become our partner?</h2>
                        <TertiaryButton :as="Link" :href="route('contact.show')" class="inline-block w-fit mt-6 hover:scale-105 transition-all duration-300 ease-out">Contact us</TertiaryButton>
                    </Card>
                </section>

                <section>
                    <h2 class="font-semibold text-xl text-slate-700 uppercase">Popular</h2>

                    <!-- Mobile -->
                    <div class="swiper-container-wrapper mt-2 block md:hidden">
                        <swiper-container
                            class="mySwiper -mx-3 w-full"
                            :space-between="16"
                            :slides-per-view="1.2"
                            :navigation="true"
                            :pagination="false"
                            :scrollbar="false"
                            :mousewheel="true"
                            :slidesOffsetBefore="0"
                            :slidesOffsetAfter="0"
                            :mousewheel-force-to-axis="true"
                            :injectStyles="[
                                `.swiper {padding: 0 0.75rem;}`,
                                `.swiper-button-next { margin-right: 0.25rem; }`,
                                `.swiper-button-prev { margin-left: 0.25rem; }`,
                            ]"
                            style="--swiper-navigation-color: #6212E5; --swiper-navigation-size: 1.5rem;"
                            :style="{
                                '--swiper-btn-prev-opacity': popularSwiperProgress === 0 ? 0 : 1,
                                '--swiper-btn-next-opacity': popularSwiperProgress === 1 ? 0 : 1,
                            }"
                            @swiperprogress="onPopularSwiperProgress"
                        >
                            <div slot="container-start" v-if="popularSwiperProgress !== 0 && popularSimulations.length > getSlidesPerView(popularSimulations.length)">
                                <div class="swiper-fade-left"></div>
                            </div>
                            <div slot="container-end" v-if="popularSwiperProgress !== 1 && popularSimulations.length > getSlidesPerView(popularSimulations.length)">
                                <div class="swiper-fade-right"></div>
                            </div>
                            <swiper-slide v-for="(simulation, index) in popularSimulations" :key="index" class="my-8 h-auto">
                                <Link 
                                    :href="route('simulations.show', { roleplayUuid: simulation.uuid })"
                                >
                                    <SimulationCard
                                        :simulation="{ ...simulation, scenario: { ...simulation.scenario, description: simulation.scenario.description.length > 100 ? simulation.scenario.description.substring(0, 100) + ' ...' : simulation.scenario.description }}"
                                        style="--shadow-color: hsla(255,75%,55%,0.5)"
                                        class="h-full"
                                    />
                                </Link>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                    <div class="mt-10 hidden md:grid gird-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-12">
                        <Link 
                            v-for="simulation in popularSimulations" 
                            :key="simulation.id"
                            :href="route('simulations.show', { roleplayUuid: simulation.uuid })"
                            class="flex"
                        >
                            <SimulationCard 
                                :simulation="simulation"
                                class="grow text-xl"
                                style="--shadow-color: hsla(255,75%,55%,0.5)"
                            />
                        </Link>
                    </div>
                </section>

                <section>
                    <Card 
                        class="max-w-screen-md mx-auto py-12 md:py-14 mt-28 md:mt-36"
                        :bgImage="Pattern"
                    >
                        <div class="max-w-xl mx-auto">
                            <h1 class="text-3xl md:text-4xl font-bold text-center">Create your roleplay</h1>
                            <p class="text-lg md:text-xl font-semiboldz mt-4 md:mt-6 text-center">
                                Cannot find any roleplay that you can adapt? Create your own. Customize things like persona traits, roleplay background story, goal of communication, and more.
                            </p>
                        </div>
                    </Card>

                    <div class="flex justify-center mt-14">
                        <Link :href="route('simulations.create')">
                            <ShinyButton>Create roleplay</ShinyButton>
                        </Link>
                    </div>
                </section>
            </main>

            <footer class="pt-16 md:pt-32">
                <div class="flex flex-wrap gap-4 justify-center pt-12">
                    <Link :href="route('about')" class="font-semibold">About</Link>
                    <a href="https://blog.cultureboardapp.com" target="_blank" class="font-semibold">Blog</a>
                    <Link :href="route('contact.show')" class="font-semibold">Contact us</Link>
                </div>

                <div class="flex flex-wrap gap-4 justify-center py-5">
                    <Link 
                        v-for="menuItem in [
                            { name: 'Privacy Policy', url: route('privacy') },
                            { name: 'Terms of service', url: route('terms') }
                        ]"  
                        :href="menuItem.url"
                        class="text-sm text-slate-600"
                    >{{ menuItem.name }}</Link>
                </div>
            </footer>
        </div>
    </div>
</template>

<style>
swiper-container::part(button-prev) {
    opacity: var(--swiper-btn-prev-opacity, 1);
}

swiper-container::part(button-next) {
    opacity: var(--swiper-btn-next-opacity, 1);
}

.swiper-fade-left,
.swiper-fade-right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  z-index: 10;
  pointer-events: none;
  transition: opacity 0.3s;
}

.swiper-fade-left {
  left: 0;
  background: linear-gradient(to right, rgba(217, 204, 255, 0.9), rgba(0, 0, 0, 0));
}

.swiper-fade-right {
  right: 0;
  background: linear-gradient(to left, rgba(180, 203, 241, 0.9), rgba(0, 0, 0, 0));
}

.swiper-container-wrapper:hover .swiper-fade-left,
.swiper-container-wrapper:hover .swiper-fade-right {
  opacity: 1;
  pointer-events: auto;
}
</style>
